import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import OutlinedCounterCard from 'rootComponents/Cards/CounterCards/OutlinedCounterCard';
import TransparentInfoCard from 'rootComponents/Cards/InfoCards/TransparentInfoCard';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import DataTable from 'rootComponents/Tables/DataTable';

import DefaultCell from './components/DefaultCell';
import ProductCell from './components/ProductCell';
import ReferralCode from './components/ReferralCode';
import { useGetReferral } from './hooks';
function Referral() {
  const { data: referralData } = useGetReferral();

  const getRows = () => {
    return _.map(_.get(referralData, 'referred_users', []), (user) => {
      const allTrans = _.get(referralData, 'transactions', []);
      const trans = _.filter(allTrans, (t) => t.user_id === user);
      const paid = trans.map((t) => t.amount).reduce((a, b) => a + b, 0);
      const reward = trans
        .map((t) => t.commission_amount)
        .reduce((a, b) => a + b, 0);

      return {
        user: <ProductCell name={`${user}`} orders={trans.length} />,
        total_paid: <DefaultCell>${paid.toFixed(2)}</DefaultCell>,
        total_reward: <DefaultCell>${reward.toFixed(2)}</DefaultCell>,
      };
    });
  };

  return (
    <MainLayout>
      <MainNavbar />

      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={2}>
              <SoftBox mb={1}>
                <SoftTypography variant='h5' fontWeight='medium'>
                  Referral Program
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant='body2' fontWeight='regular' color='text'>
                Track and find all the details about our referral program, your
                stats and revenues.
              </SoftTypography>
            </SoftBox>
            <SoftBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} lg={3}>
                  <OutlinedCounterCard
                    count={_.get(referralData, 'summary.total_earned', 0)}
                    suffix={_.get(referralData, 'summary.currency', '$')}
                    title='earnings'
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OutlinedCounterCard
                    count={_.get(referralData, 'referred_users', []).length}
                    prefix=''
                    title='referred users'
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OutlinedCounterCard
                    count={_.get(referralData, 'summary.avg_earned', 0)}
                    suffix={_.get(referralData, 'summary.currency', '$')}
                    title='avg. earnings'
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OutlinedCounterCard
                    count={_.get(referralData, 'summary.avg_commission', 0)}
                    suffix='%'
                    title='commission rate'
                  />
                </Grid>
              </Grid>
              <SoftBox mt={6} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <ReferralCode />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SoftTypography variant='h6' fontWeight='medium'>
                      How to use
                    </SoftTypography>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant='button'
                        color='text'
                        fontWeight='regular'
                      >
                        Integrate your referral link in 2 easy steps.
                      </SoftTypography>
                    </SoftBox>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TransparentInfoCard
                          color='dark'
                          icon='paid'
                          description='1. Create & validate your referral link'
                          value={<></>}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TransparentInfoCard
                          color='dark'
                          icon='unarchive'
                          description='2. For every order you make you get 20% of the service fee'
                          value={<></>}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <Card>
          <SoftBox pt={3} px={3}>
            <SoftTypography variant='h6' fontWeight='medium'>
              Top Referred Users
            </SoftTypography>
          </SoftBox>
          <DataTable
            table={{
              columns: [
                { Header: 'user', accessor: 'user', width: '40%' },
                { Header: 'total paid', accessor: 'total_paid' },
                {
                  Header: 'total reward',
                  accessor: 'total_reward',
                  align: 'center',
                },
              ],
              rows: getRows(),
            }}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
          />
        </Card>
      </SoftBox>
    </MainLayout>
  );
}

export default Referral;

